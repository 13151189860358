export default function () {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  const flatfinders = get('customFields.flatfinder') ? get('flatfinders', 'Root.item')
    .filter(flatfinder => flatfinder.id === get('customFields.flatfinder')) : null

  console.log(get('flatfinders', 'Root.item'))

  return [
    {
      condition: get('media.cover.length') || get('content'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'font-size: 1.25rem;',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              gap: '3rem',
              columns: '1',
            },
            blocks: {
              default: [
                {
                  component: 'Slider',
                  data: get('media.cover'),
                  condition: get('media.cover.length'),
                },
                {
                  component: 'Text',
                  props: { 'text-align': 'center', style: 'max-width: 620px; margin:0 auto' },
                  data: {
                    content: get('content'),
                  },
                  condition: get('content'),
                },
              ],
            },
          },
        ],
      },
    },

    {
      condition: get('customFields.additional-sections.0.image.url'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            component: 'ImageText',
            data: {
              content: get('customFields.additional-sections'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('customFields.page-settings.display-flatfinder') && get('customFields.flatfinder'),
      blocks: {
        default: {
          component: 'Flatfinder',
          data: {
            flatfinders
          }
        }
      }
    },

    {
      component: 'Section',
      condition: get('customFields.page-settings.display-map'),
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
              displayAddress: false
            },
            data: {
              ...get('address', 'Root.item'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'lead',
        class: 'section-contact',
        style: 'background-color: var(--light-grey);',
      },
      blocks: {
        default: {
          component: 'Grid',
          props: {
            gap: '2rem 4rem',
            columns: '2:1',
          },
          blocks: {
            default: [
              {
                component: 'Lead',
                props: {
                  options: {
                    placeholders: {
                      'contact.name': this.$t('name'),
                      'contact.email': this.$t('email'),
                      'contact.phone': this.$t('phone'),
                      comment: this.$tc('message', 1),
                    },
                    fields: ['!title'],
                    actionLabel: this.$t('send'),
                  },
                },
                data: {
                  project: get('Root.item.id'),
                  reference: { onModel: 'Project', ref: get('Root.item.id') },
                },
                blocks: {
                  top: [
                    {
                      component: 'Text',
                      data: {
                        content: get('Root.item.customFields.lead-top-content'),
                      },
                    },
                  ],
                },
              },
              {
                component: 'Flex',
                props: {
                  flexDirection: 'column',
                  gap: '4rem',
                },
                blocks: {
                  default: [
                    {
                      component: 'Openhouse',
                      props: {
                        class: 'project-openhouse',
                      },
                      data: {
                        content: get('openhouses', 'Root.item'),
                      },
                      condition: () => {
                        if (!this.item) return false
                        if (get('openhouses.length', 'Root.item')) return true
                        return !get('metadata.siteSettings.hideNoOpenhouse', 'Root.item')
                      },
                    },
                    {
                      component: 'ContactList',
                      data: {
                        contacts: get('roles.salesman', 'Root.item'),
                      },
                      props: {
                        showLogo: false,
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    },
  ].filter(f => !('condition' in f) || f.condition)
}
